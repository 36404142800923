import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import Page from '~/components/Page';
import { FlexBox } from '~/components/.base/containers';
import { getImage } from 'helpers';

const AccessibilityPolicy = ({ data, location }) => {
  return (
    <>
      <Page data={data} location={location} />
      <FlexBox justify="center">
        {data && data.accessibilityImages ? (
          <div style={{ width: '300px', margin: '-100px auto 5rem' }}>
            <Image
              fluid={getImage(data.accessibilityImages.edges, 'review')}
              alt="Reviewed by Allyant"
              className="slide-img-up"
              loading="lazy"
            />
          </div>
        ) : null}
      </FlexBox>
    </>
  );
};

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "accessibility" }) {
      title
      content {
        raw
      }
    }
    accessibilityImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/accessibility/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1600, quality: 80) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            original {
              width
            }
          }
        }
      }
    }
  }
`;

export default AccessibilityPolicy;
