import React from 'react'

import {
    Container
} from "~/components/.base/containers"
import {
    Rte
} from '~/components/.base/headings'

const Content = ({children}) => {
    return <Rte>{children}</Rte>
}

export default Content