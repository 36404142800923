import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import SEO from "~/components/seo";
import { DeriveHeaderClasses } from "~/components/Navigation";
import Header from "~/components/Page/Header/Header";
import Content from "~/components/Page/Content/Content";
import { Container } from "~/components/.base/containers";
import { css } from "styled-components";

const Page = ({ data, location }) => {
  const {
    contentfulPage: { title, content }
  } = data;

  return (
    <>
      <SEO title={title} />
      <DeriveHeaderClasses location={location} />
      <Container
        css={css`
          width: 970px;
          max-width: 100%;
        `}
      >
        <Header>{title}</Header>
        <Content>{renderRichText(content)}</Content>
      </Container>
    </>
  );
};

export default Page;
