import React from 'react';
import { css } from 'styled-components';

import { HeadingLarge } from '~/components/.base/headings';

const Header = ({ children }) => {
  return (
    <HeadingLarge
      md={`80px 0 60px`}
      css={css`
        text-align: center;
      `}
    >
      {children}
    </HeadingLarge>
  );
};

export default Header;
